export default {
  'clubs.detailSingin': 'Thông tin đăng nhập',
  'clubs.status': 'Trạng thái hoạt động',
  'clubs.status.active': 'Đang hoạt động',
  'clubs.status.not-active': 'Ngưng hoạt động',
  'clubs.non-delete.title': 'Không thể xóa',
  'clubs.non-delete.content': 'Không thể xóa Liên Đội đang hoạt động',
  'clubs.delete.title': 'Xóa Liên Đội',
  'clubs.delete.content': 'Bạn có muốn xóa Liên Đội đã chọn',
  'clubs.account-infomation': 'Thông tin đăng nhập',

  'clubs.name': 'Liên Đội',
  'clubs.listOfClubs': 'Danh sách Liên Đội',
  'clubs.add': 'Thêm Liên Đội mới',
  'clubs.code': 'Mã Liên Đội',
  'clubs.enterpriseName': 'Tên đơn vị',
  'clubs.quantity': 'Chi Đội (Số lượng)',
  'clubs.provinceCode': 'Địa chỉ',
  'clubs.districtCode': 'Quận / Huyện',
  'clubs.communeCode': 'Phường / Xã',
  'clubs.placeholder.provinceCode': 'Nhập Tỉnh / Thành phố',
  'clubs.placeholder.districtCode': 'Nhập Quận /Huyện',
  'clubs.placeholder.communeCode': 'Nhập Phường / Xã',
  'clubs.road': 'Đường',
  'clubs.emailAddress': 'Email',
  'clubs.headOfficeAddress': 'Địa chỉ',
  'clubs.status.param':
    '{status,select, 1 {Đang hoạt động} 2 {Dừng hoạt động} other {Không xác định}}',
  'clubs.action': 'Hành động',
  'clubs.info': 'Chi tiết',
  'clubs.edit': 'Cập nhật',
  'clubs.represents': 'Phụ trách Đội',
  'clubs.member': 'Danh sách Đội viên',
  'clubs.table.quantity': 'Số lượng Đội viên',
  'clubs.view': 'Chi tiết Liên Đội',
  'clubs.infomation': 'Thông tin Liên Đội',
  'clubs.countOfMember': 'Đội viên (Số lượng)',

  'clubs.list-represents': 'Phụ trách Đội',
  'represents.code': 'Mã',
  'represents.name': 'Họ và tên',
  'represents.gender': 'Giới tính',
  'represents.gender.male': 'Nam',
  'represents.gender.female': 'Nữ',
  'represents.dayOfBirth': 'Ngày sinh',
  'represents.phoneNumber': 'Số điện thoại',
  'clubs.label.emailAddress': 'Email',
  'represents.add': 'Thêm Phụ trách Đội',
  'represents.placeholder.dayOfBirth': 'Nhập ngày sinh',
  'represents.personal-infomation': 'Thông tin cá nhân',
  'represents.about-clubs': 'Về Đội',
  'represents.title': 'Chức danh',
  'represents.prorogues': 'Nhiệm kỳ',
  'represents.emailAddress': 'Email',
  'represents.placeholder.prorogues': 'Nhập nhiệm kỳ',
  'represents.action': 'Hành động',
  'represents.info': 'Chi tiết',
  'represents.edit': 'Cập nhật',
  'represents.view': 'Chi tiết Phụ trách Đội',
  'represents.delete.title': 'Xóa Phụ trách Đội',
  'represents.delete.content': 'Bạn có muốn xóa Phụ trách Đội đã chọn',

  'clubs.member.heading': 'Danh sách Đội viên',
  'clubs.list-members': 'Đội viên',
  'clubs.member.add': 'Thêm Đội viên',
  'clubs.member.title': 'Đội viên',
  'member.code': 'Mã Đội viên',
  'member.idCard': 'Mã thẻ',
  'member.name': 'Họ và tên',
  'member.grade': 'Khối lớp',
  'member.workUnit': 'Chi Đội',
  'member.phoneNumber': 'Số điện thoại',
  'member.avatar': 'Ảnh đại diện',
  'member.personal-infomation': 'Thông tin cá nhân',
  'member.placeholder.grade': 'Chọn khối lớp',
  'leaderInfo.name': 'Họ và tên',
  'leaderInfo.position': 'Quan hệ',
  'leaderInfo.emailAddress': 'Email',
  'member.leaderInfo': 'Thông tin người đại diện',
  'member.info': 'Chi tiết',
  'member.edit': 'Cập nhật',
  'member.action': 'Hành động',
  'clubs.member.edit': 'Cập nhật Đội viên',
  'member.non-delete.title': 'Không thể xóa',
  'member.non-delete.content': 'Không thể xóa Đội viên đang hoạt động',
  'member.delete.title': 'Xóa Đội viên',
  'member.delete.content': 'Bạn có muốn xóa Đội viên đã chọn',
  'clubs.member.view': 'Chi tiết Đội viên',
  'member.clubs': 'Liên Đội',

  // update on 09/10/2023
  'clubs.searhModal.title': 'Tìm kiếm đội viên',
  'clubs.search-label': 'Từ khóa',
  'clubs.search': 'Tìm',
  'clubs.result-search': 'Không tìm thấy đội viên.',
  'clubs.result-search-page': 'Kết quả tìm kiếm',
  'clubs.result-search-page.name': 'Tên đội viên',
  'clubs.type': 'Loại đội viên',
  'clubs.memberPersonal': 'Đội viên',
  'clubs.club-represent': 'Phụ trách đội',
  'clubs.meritBadges': 'Thành tích',
  'clubs.rewards': 'Giấy khen',
};
